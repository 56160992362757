import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon
import './LandingPage.css'; // Import the CSS file
import img1 from './images/1.jpg'; // Import the image correctly
import img2 from './images/2.png'; // Import the image correctly

const LandingPage = () => {
  const [promoCodeVisible, setPromoCodeVisible] = useState(true);
  const [whatsappButtonDisabled, setWhatsappButtonDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(18 * 59 * 23); // Countdown in seconds (6 hours)

  useEffect(() => {
    const loadFacebookPixel = () => {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq = window.fbq || function() {};
      window.fbq('init', '295669516421830'); // Replace with your Pixel ID
      window.fbq('track', 'PageView');
    };

    loadFacebookPixel();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  const handleWhatsAppClick = () => {
    if (window.fbq) {
      window.fbq('track', 'Lead');
    }
    window.location.href = 'https://api.whatsapp.com/send?phone=60183943250&text=PromosiSlimmingPanties';
  };

  // Format time into hours, minutes, and seconds
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  return (
    <div className="landing-container">
      <img
        src="https://png.pngtree.com/png-clipart/20230710/ourmid/pngtree-flowers-png-image_7506581.png"
        alt="Aizira Logo"
        className="landing-logo"
      />
      <h1 className="landing-heading">
        Aizira Malaysia <span className="superscript">HQ</span>
      </h1>
      <h4 className="landing-heading">
        End Year Promo 2024 <span className="superscript"></span>
      </h4>
      <h2 className="countdown-timer">Promosi Tamat Dalam Masa:</h2>
      <h1 className="landing-heading1">{formatTime(timeLeft)}</h1>
      <img src={img1} alt="Promo" className="promo-image" />
      {promoCodeVisible && (
        <button 
          className="landing-button"
          onClick={handleWhatsAppClick}
          disabled={whatsappButtonDisabled}
        >
          WhatsApp Sekarang <FaWhatsapp />
        </button>
      )}
      <img src={img2} alt="Promo" className="promo-image" />
      {promoCodeVisible && (
        <button 
          className="landing-button"
          onClick={handleWhatsAppClick}
          disabled={whatsappButtonDisabled}
        >
          WhatsApp Sekarang <FaWhatsapp />
        </button>
      )}
    </div>
  );
};

export default LandingPage;
