import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MyPage from './MyPage';
import LandingPage from './LandingPage';
import OrderForm from './OrderForm';
import './App.css';

const AppContent = () => {
  const location = useLocation();
  
  // Hide the navbar on the login page (optional logic for future use)
  const isLoginPage = location.pathname === '/';

  return (
    <>
      {/* Render the navbar conditionally if needed */}
      {!isLoginPage && <nav> {/* Add Navbar Component Here */} </nav>}
      
      {/* Define routes */}
      <Routes>
        {/* <Route path="/landing" element={<LandingPage />} />
        <Route path="/" element={<OrderForm />} /> */}
        <Route path="/promosiendyear2024" element={<LandingPage />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/mypage" element={<MyPage />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
