import React from 'react';
import './MyPage.css'; // Import the CSS file for styling
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel's CSS

const MyPage = () => {
  return (
    <div className="page-container">
      <header className="page-promo">
        <p>Promosi Bulan Ini!</p>
      </header>
      <header className="page-promo1">
        <p>Aizira Malaysia</p>
      </header>
      <header className="page-header">
        <h1>Your Header Title</h1>
      </header>
      
      <div className="image-container">
        <img 
          src="https://erxwsgtiyeekawlshbbn.supabase.co/storage/v1/object/public/rfidboomgatephoto/1723904758886.jpg" 
          alt="Description of the image"
          className="full-width-image"
        />
        <h3 className="image-description">This is the description below the image.</h3>
        <p className="image-description">
          This is the description below the image. This is the description below the image. 
          This is the description below the image. This is the description below the image. 
          This is the description below the image. This is the description below the image.
        </p>
      </div>
      
      <div className="image-container">
        <img 
          src="https://erxwsgtiyeekawlshbbn.supabase.co/storage/v1/object/public/rfidboomgatephoto/1723904758886.jpg" 
          alt="Description of the image"
          className="full-width-image"
        />
        <p className="image-description">This is the description below the image.</p>
      </div>

      {/* Product Benefits Section */}
      <section className="product-benefits">
        <h2>Our Product Benefits</h2>
        <div className="cards-container">
          <div className="card">
            <h3>Benefit 1</h3>
            <p>Description of benefit 1.</p>
          </div>
          <div className="card">
            <h3>Benefit 2</h3>
            <p>Description of benefit 2.</p>
          </div>
          <div className="card">
            <h3>Benefit 3</h3>
            <p>Description of benefit 3.</p>
          </div>
          <div className="card">
            <h3>Benefit 4</h3>
            <p>Description of benefit 4.</p>
          </div>
        </div>
      </section>

      {/* Carousel Gallery Section */}
      <section className="carousel-gallery">
        <h2>Our Gallery</h2>
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
        >
          <div>
            <img src="https://erxwsgtiyeekawlshbbn.supabase.co/storage/v1/object/public/rfidboomgatephoto/1723904758886.jpg" alt="Gallery Image 1" />
            <p className="legend">Gallery Image 1</p>
          </div>
          <div>
            <img src="https://erxwsgtiyeekawlshbbn.supabase.co/storage/v1/object/public/rfidboomgatephoto/1723904758886.jpg" alt="Gallery Image 2" />
            <p className="legend">Gallery Image 2</p>
          </div>
          <div>
            <img src="https://erxwsgtiyeekawlshbbn.supabase.co/storage/v1/object/public/rfidboomgatephoto/1723904758886.jpg" alt="Gallery Image 3" />
            <p className="legend">Gallery Image 3</p>
          </div>
        </Carousel>
      </section>

      {/* Pricing Table Section */}
      <section className="pricing-table">
        <h2>Our Pricing Plans</h2>
        <div className="pricing-container">
          <div className="pricing-card">
            <h3>Basic Plan</h3>
            <p className="price">$19.99/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul>
            <button className="pricing-button">Choose Basic</button>
          </div>
          <div className="pricing-card">
            <h3>Standard Plan</h3>
            <p className="price">$29.99/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
            </ul>
            <button className="pricing-button">Choose Standard</button>
          </div>
          <div className="pricing-card">
            <h3>Premium Plan</h3>
            <p className="price">$49.99/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
              <li>Feature 5</li>
            </ul>
            <button className="pricing-button">Choose Premium</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyPage;
